import type { Side } from '@floating-ui/utils';
import type { CSSProperties } from 'react';
import { TooltipTransition } from './Tooltip.types';

/**
 * Width of the arrow of the tooltip
 */
export const ARROW_WIDTH = {
  px: 12,
  rem: 0.75,
};

/**
 * Height of the arrow of the tooltip
 */
export const ARROW_HEIGHT = {
  px: 7,
  rem: 0.4375,
};

/**
 * Distance between tooltip and element reference in px
 */
export const ARROW_OFFSET = {
  px: 4,
  rem: 0.25,
};
/**
 * Amplitude of animation translation
 */
const TRANSITION_OFFSET = {
  px: 7,
  rem: 0.4375,
};
/**
 * Durations of animations
 */
export const TRANSITION_DURATIONS = {
  open: 200,
  close: 100,
};

/**
 * Dictionnay of transitions for Floating UI from TooltipTransition and Side position
 */
export const TRANSITIONS: {
  [keyTransition in TooltipTransition]: { [keySide in Side]: CSSProperties };
} = {
  [TooltipTransition.MoveFade]: {
    'top': {
      opacity: 0,
      transform: `translateY(-${TRANSITION_OFFSET.rem}rem)`,
    },
    'bottom': {
      opacity: 0,
      transform: `translateY(${TRANSITION_OFFSET.rem}rem)`,
    },
    'right': {
      opacity: 0,
      transform: `translateX(${TRANSITION_OFFSET.rem}rem)`,
    },
    'left': {
      opacity: 0,
      transform: `translateX(-${TRANSITION_OFFSET.rem}rem)`,
    },
  },
  [TooltipTransition.Fade]: {
    'top': {
      opacity: 0,
    },
    'bottom': {
      opacity: 0,
    },
    'right': {
      opacity: 0,
    },
    'left': {
      opacity: 0,
    },
  },
  [TooltipTransition.NoTransition]: {
    'top': {},
    'bottom': {},
    'right': {},
    'left': {},
  },
};
