import { Button } from '@canalplus/dive';
import type { IVirtualKeyboardContext } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import type { JSX } from 'react';
import { useContext } from 'react';
import {
  VirtualKeyboard,
  virtualKeyboardContext,
} from '../../../../components/VirtualKeyboard/VirtualKeyboard';
import { useOnFocusable } from '../../../../helpers/hooks/useOnFocusable';
import { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import { MIDDLEWARE_FUNNEL_PROMOTIONAL } from '../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../lang';
import { VoucherInput } from './VoucherInput';
import styles from './VoucherStep.css';

import type { VoucherEntryBar } from '@dce-front/hodor-types/modules/tvod/vouchers/definitions';
import { TvodTitle } from '../TvodTitle/TvodTitle';

type VoucherStepProps = {
  entryBar: VoucherEntryBar;
  onSubmit?: (voucherKey: string) => void;
  focusManager?: FocusManager;
};

/**
 * Component voucher step for funnel onyl for tv device
 */
export function VoucherStep({
  entryBar,
  onSubmit,
  focusManager,
}: VoucherStepProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const { deportedScreenTitle, deportedScreenSubtitle, placeHolderLabel } =
    entryBar || {};

  const {
    state: { inputValue },
  } = useContext<IVirtualKeyboardContext>(virtualKeyboardContext);

  /**
   * Input value from **VirtualKeyboard** is a `string[]`\
   * We are transforming it into a simple string
   */
  const joinedInputValue = inputValue.join('').toUpperCase();

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (joinedInputValue) {
      onSubmit?.(joinedInputValue);
    }
  };

  useOnFocusable(focusManager);

  return (
    <Binder
      middleware={MIDDLEWARE_FUNNEL_PROMOTIONAL}
      className={styles.voucherStep}
    >
      {/* Update component size subtitle because voucher step subtitle is specific */}
      <TvodTitle
        title={deportedScreenTitle || 'Voucher'}
        subtitle={deportedScreenSubtitle}
      />
      <form>
        <VoucherInput
          placeHolderLabel={placeHolderLabel || 'Voucher'}
          value={joinedInputValue}
        />
        <Button width="fixed" type="submit" onClick={handleSubmit}>
          {t('FunnelTvod.validate')}
        </Button>
      </form>
      <div className={styles.voucherStep__virtualKeyboardWrapper}>
        <VirtualKeyboard />
      </div>
    </Binder>
  );
}
