import {
  AcmError,
  getPurchaseCodeStatus as getPurchaseCodeStatusFunction,
} from '@canalplus/sdk-acm';
import { useFetchAcmSdk } from '@canalplus/sdk-acm-react';
import type { TurboPlatform } from '@canalplus/sdk-core';
import { PurchaseCodeStatusValue } from '@canalplus/types-acm';
import type { ApiV2TvodTracking } from '@dce-front/hodor-types/api/v2/common/dto/tvod/defintions';
import type { ApiV2TvodPaymentState } from '@dce-front/hodor-types/api/v2/tvod/payment/definitions';
import type {
  ApiV2PaymentMeansDetail,
  ApiV2PaymentMeansPurchase,
} from '@dce-front/hodor-types/api/v2/tvod/payment_means/definitions';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  CB1SHOT_PAYMENTS,
  CB_TVOD_PAYMENTS,
  IBAN_PAYMENTS,
  RECURRENT_CB_PAYMENT_CODE,
} from '../../../../../../constants/tvod';
import { isTim } from '../../../../../../helpers/application/application-helper';
import { useIsTvDevice } from '../../../../../../helpers/hooks/useIsTvDevice';
import { passTokenSelector } from '../../../../../../store/slices/user-selectors';
import { FunnelInnerStep } from '../../../../../../templates/FunnelTvod/stores/constants';
import {
  amendFunnelHistory,
  incrementFunnelHistory,
  setCurrentStep,
} from '../../../../../../templates/FunnelTvod/stores/funnel/actions';
import {
  useFunnelCurrentStep,
  useFunnelDispatch,
  useFunnelPaymentMean,
} from '../../../../../../templates/FunnelTvod/stores/funnel/hooks';
import { usePayment } from '../../../hooks/usePayment/usePayment';

/**
 * On legacy tvod payment logical in front for display purchase code
 * On urbanized platform, no front rules - The instructions come from the back end pinPurchaseRequired
 * @param isFree content with promotional and promotionalPrice = 0
 */
function useDisplayPurchaseCode(isFree?: boolean): boolean {
  const paymentMean = useFunnelPaymentMean();
  // TVOD URBA
  if (isTim()) {
    return paymentMean?.pinPurchaseRequired || false;
  }
  /**
   * See #10783
   * To delete KISS LEGACY
   */
  if (isFree) {
    return true;
  }
  switch (paymentMean?.paymentMeanCode) {
    case CB_TVOD_PAYMENTS:
    case CB1SHOT_PAYMENTS:
      return false;
    case IBAN_PAYMENTS:
    case RECURRENT_CB_PAYMENT_CODE:
      return true;
    default:
      return false;
  }
}

/**
 * Hook that check purchase code status and display purchase code screen if needed
 * @param isFree content with promotional and promotionalPrice = 0
 * @returns Callback function that handles purchase code screen
 */
export const usePurchaseCode = (
  isFree?: boolean
): (() => Promise<{ isActivated?: boolean }>) => {
  const passToken = useSelector(passTokenSelector);
  const isTvDevice = useIsTvDevice();

  // Funnel hooks
  const currentStep = useFunnelCurrentStep();
  const funnelDispatch = useFunnelDispatch();
  const isDisplayPurchaseCode = useDisplayPurchaseCode(isFree);
  // SDK-ACM-REACT hooks
  const getPurchaseCodeStatus = useFetchAcmSdk(getPurchaseCodeStatusFunction, {
    passToken,
  });

  return useCallback(async () => {
    try {
      // Check if PurchaseCode exists
      const { status: purchaseCodeStatus } = await getPurchaseCodeStatus();

      // Handle process depending on PurchaseCode status
      const newInnerStep = {
        ...currentStep,
        innerStep: FunnelInnerStep.PurchaseCode,
      };
      switch (purchaseCodeStatus.value) {
        case PurchaseCodeStatusValue.Activated:
          if (isDisplayPurchaseCode) {
            // Load PurchaseCode InnerStep
            funnelDispatch(setCurrentStep(newInnerStep));
            if (!isTvDevice) {
              // We increment for the web in order to have a back button properly working
              funnelDispatch(incrementFunnelHistory(newInnerStep));
            } else {
              funnelDispatch(amendFunnelHistory(newInnerStep));
            }
          }
          return { isActivated: isDisplayPurchaseCode };
        case PurchaseCodeStatusValue.Disabled:
        case PurchaseCodeStatusValue.NotSetup:
        default:
          break;
      }
    } catch (error) {
      if (error instanceof AcmError) {
        return { isActivated: false };
      }
    }
    return { isActivated: false };
  }, [
    getPurchaseCodeStatus,
    currentStep,
    funnelDispatch,
    isTvDevice,
    isDisplayPurchaseCode,
  ]);
};

/**
 * Hook that returns a callback capable of handling the submit payment action.
 * What it does:
 * - Process payment immediately
 * - Display safety purchase code screen
 * - Redirect to CheckPaymentPage for CB1Shot and RCC
 *
 * @param turboMedia media send by hodor template
 * @param purchase purchase object
 * @param detail detail object
 * @returns Callback function that handles payment
 */
export const useHandleSubmitPayment = ({
  currentContent,
  purchase,
  turboMedia,
  tracking,
  detail,
  isFree,
}: {
  currentContent?: ApiV2TvodPaymentState[];
  purchase?: ApiV2PaymentMeansPurchase;
  turboMedia?: TurboPlatform;
  tracking?: ApiV2TvodTracking;
  detail?: ApiV2PaymentMeansDetail;
  isFree?: boolean;
}): (() => Promise<void>) => {
  // Custom hooks - Handlers
  const handlePurchaseCode = usePurchaseCode(isFree);
  const handlePay = usePayment({
    currentContent,
    purchase,
    turboMedia,
    tracking,
    detail,
    isFree,
  });

  return useCallback(async () => {
    /**
     * * Handle PurchaseCode (ACM)
     *  - PurchaseCode enabled
     *    - KISS: Always display InnerSTEP PurchaseCode
     *    - TURBO: PinRequired to show InnerSTEP PurchaseCode
     *  - PurchaseCode does not exist: Continue payment process
     */
    const { isActivated: isPurchaseCodeActivated } = await handlePurchaseCode();

    if (isPurchaseCodeActivated) {
      // Payment process needs to be handled by the PurchaseCode screen at the end
      // of the `Check` PurchaseCode process.
      return;
    } else {
      await handlePay();
    }
  }, [handlePurchaseCode, handlePay]);
};
