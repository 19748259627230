import { PlaysetQuality, isPlaysetQuality } from '@canalplus/mycanal-commons';
import type { JSX } from 'react';
import { QualityIcon } from './QualityIcon';

// From best to worst quality
const WHITELISTED_QUALITIES_BY_PRIORITY = {
  'audio': [
    PlaysetQuality.DolbyAtmos,
    PlaysetQuality.Dolby_5_1,
    PlaysetQuality.Dolby_5_1WithSpace,
  ],
  'video': [
    PlaysetQuality.UhdDbv,
    PlaysetQuality.UhdHdr,
    PlaysetQuality.Uhd,
    PlaysetQuality.Hd,
  ],
};

export type QualitiesProps = {
  qualities?: PlaysetQuality[] | string[]; // audio or video (never both together)
  type?: keyof typeof WHITELISTED_QUALITIES_BY_PRIORITY;
  bestIconOnly?: boolean;
};

/**
 * Displays the list of all icons or only the first prioritized whitelisted quality icon of the list.
 * @param qualities     The list of video/audio **qualities**
 * @param type          The **type** of the quality list `audio | video`
 * @paran bestIconOnly  Optional flag that indicates we only want to show the first and stongest icon of the list
 * @returns             Returns all the quality icons to be displayed or the mvp icon only
 */
export function Qualities({
  qualities = [],
  type,
  bestIconOnly = false,
}: QualitiesProps): JSX.Element | null {
  const supportedQualities = qualities.filter(
    (quality: PlaysetQuality | string) => isPlaysetQuality(quality)
  ) as PlaysetQuality[];

  const isDolby51Duplicated =
    supportedQualities.includes(PlaysetQuality.Dolby_5_1) &&
    supportedQualities.includes(PlaysetQuality.Dolby_5_1WithSpace);

  // Remove duplicate and obsolete qualities ([...new Set(array)] removes duplicates)
  //  - remove obsolete "HDR" playset quality from the list
  //  - remove obsolete "DOLBY 5.1" (with space) playset quality from the list if "DOLBY_5.1" is already present
  const deduplicatedQualities = Array.from(
    new Set(
      supportedQualities.filter(
        (quality) =>
          quality !== PlaysetQuality.Hdr &&
          !(
            isDolby51Duplicated && quality === PlaysetQuality.Dolby_5_1WithSpace
          )
      )
    )
  );

  const getBestQualityIcon = (
    qualityIcons: string[],
    iconType: keyof typeof WHITELISTED_QUALITIES_BY_PRIORITY
  ): PlaysetQuality[] => {
    const bestIcon = WHITELISTED_QUALITIES_BY_PRIORITY[iconType].find(
      (currentBestIcon) => qualityIcons.includes(currentBestIcon)
    );
    return bestIcon ? [bestIcon] : [];
  };

  const icons =
    bestIconOnly && type
      ? getBestQualityIcon(deduplicatedQualities, type)
      : deduplicatedQualities;

  return icons?.length ? (
    <>
      {icons.map((quality) => (
        <QualityIcon quality={quality} key={`quality-icon-${quality}`} />
      ))}
    </>
  ) : null;
}
