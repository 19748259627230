import { Alert, AlertStatus, Button } from '@canalplus/dive';
import { castToEnum } from '@canalplus/mycanal-commons';
import type { AlertBox } from '@dce-front/hodor-types/modules/contextual_offer_page/definitions';
import type { VoucherEntryBar as VoucherEntryBarType } from '@dce-front/hodor-types/modules/tvod/vouchers/definitions';
import classNames from 'classnames';
import type React from 'react';
import type { JSX } from 'react';
import { useState } from 'react';
import styles from './VoucherEntryBar.module.css';

type VoucherEntryBarProps = {
  entryBar: VoucherEntryBarType;
  alertBox?: AlertBox;
  onSubmit?: (voucherKey: string) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  title?: string;
};

export function VoucherEntryBar({
  entryBar,
  alertBox,
  onSubmit,
  onChange,
  title,
}: VoucherEntryBarProps): JSX.Element {
  const [voucherValue, setVoucherValue] = useState<string>('');
  const { placeHolderLabel, action } = entryBar;
  const { label } = action || {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVoucherValue(event.target.value.toUpperCase());
    onChange?.(event);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (voucherValue) {
      onSubmit?.(voucherValue);
    }
  };

  return (
    <form autoComplete="off" className={styles.voucherEntryBar}>
      <div className={styles.voucherEntryBar__inputWrapper}>
        <input
          className={classNames(styles.voucherEntryBar__input, {
            [styles['voucherEntryBar__input--error']!]:
              alertBox && alertBox.state === AlertStatus.Error,
          })}
          type="text"
          placeholder={placeHolderLabel}
          name="voucherEntryBar"
          onChange={handleChange}
          value={voucherValue}
          title={title}
        />
        {!!alertBox?.label && (
          <Alert
            variant="inputFieldAlertAttached"
            status={castToEnum(AlertStatus)(alertBox.state)}
            message={alertBox.label}
          />
        )}
      </div>
      <Button
        type="submit"
        font="hind"
        variant="tertiary"
        disabled={!voucherValue}
        className={styles.voucherEntryBar__button}
        onClick={handleSubmit}
        width="fit"
      >
        {label}
      </Button>
    </form>
  );
}
