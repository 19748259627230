import type { FetchOptions } from '@canalplus/mycanal-fetch';
import { getPublicConfig } from '../../helpers/config/config-helper';
import Logger from '../../helpers/logger/logger-helper';
import { getDeleteCreditCardUrl } from '../../helpers/url/url-helper';
import { apiCallWithTokenpassRenewal } from '../PersoApi/PersoApi';

interface IDeleteCreditCardApiAnswer {
  returnCode?: string;
  message?: string | null;
  additionalMessage?: string | null;
  severity?: string | null;
}

/**
 * deleteCreditCard
 *
 * Deletes the selected payment mean
 *
 * @param {string} [passToken]  The pass token
 * @param {string} [contractId] id to remove
 * @returns {json|error}
 */
export const deleteCreditCard =
  (
    contractId: string
  ): Redux.ThunkAction<Promise<IDeleteCreditCardApiAnswer>> =>
  async (dispatch) => {
    try {
      const url = getDeleteCreditCardUrl(contractId);
      const apiCall = async (options: FetchOptions) => {
        const newOptions: FetchOptions = {
          headers: {
            ...options.headers,
            'Content-Type': 'application/json',
            ...(options.headers &&
              'tokenPass' in options.headers && {
                passtoken: options.headers.tokenPass,
              }),
            tokenPass: '',
          },
          method: 'POST',
          timeout: getPublicConfig().api.paymentProvider.timeout.default,
        };

        // We delete unauthorized headers to avoid potential cors errors
        if (newOptions.headers && 'tokenPass' in newOptions.headers) {
          delete newOptions.headers.tokenPass;
        }

        if (newOptions.headers && 'xx-profile-id' in newOptions.headers) {
          delete newOptions.headers['xx-profile-id'];
        }

        return fetch(url, newOptions);
      };

      const response = await dispatch(apiCallWithTokenpassRenewal(apiCall));

      if (!response) {
        throw new Error('SaleApi::deleCreditCard failed to fetch');
      }

      const json: IDeleteCreditCardApiAnswer = await response.json();

      if (!json.returnCode) {
        throw new Error('Credit card API call has no response returnCode');
      }

      return json;
    } catch (error) {
      const displayError = error instanceof Error ? error.message : error;
      Logger.error(`Error occurs during deleteCreditCard: ${displayError}`);
      return {};
    }
  };
