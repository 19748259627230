import { ModalV2, ModalV2Type } from '@canalplus/mycanal-sharedcomponent';
import { KEY_BACK, useKeyCatcher } from '@canalplus/one-navigation';
import type { JSX } from 'react';
import { useIsTvDevice } from '../../../../../helpers/hooks/useIsTvDevice';
import { LAYER_IMMERSIVE_MOREINFOS } from '../../../../../helpers/oneNavigation/layers';
import LoadableMoreInfos from '../../../../../templates/DetailV5/components/MoreInfos';

export type MoreInfosModalProps = {
  setOpenMoreInfosModal: (isMoreInfosModalOpen: boolean) => void;
  URLPage: string;
};

function MoreInfosModal({
  setOpenMoreInfosModal,
  URLPage,
}: MoreInfosModalProps): JSX.Element {
  const isTvDevice = useIsTvDevice();
  const handleClose = () => setOpenMoreInfosModal(false);
  useKeyCatcher(KEY_BACK, handleClose, LAYER_IMMERSIVE_MOREINFOS);

  return (
    <ModalV2
      type={ModalV2Type.MoreInfos}
      isTvDevice={isTvDevice}
      onBack={handleClose}
      onClose={handleClose}
      id="modal_moreinfos"
    >
      <LoadableMoreInfos url={URLPage} />
    </ModalV2>
  );
}

export default MoreInfosModal;
