import { PaymentMeanCode } from '../../../constants/tvod';
import type { PaymentMean } from '../stores/types';

/**
 * For each payment mean, we sort them by type, IBAN always first
 * @param paymentMeans - payment means
 * @returns sorted payment means
 */
export const sortByPaymentType = (
  paymentMeans: PaymentMean[]
): PaymentMean[] => {
  const iban = paymentMeans.filter(
    (paymentMean) => paymentMean.paymentMeanCode === PaymentMeanCode.IBAN
  );
  const others = paymentMeans.filter(
    (paymentMean) => paymentMean.paymentMeanCode !== PaymentMeanCode.IBAN
  );
  return [...iban, ...others];
};
