import type { HashKey } from '@canalplus/mycanal-util-react';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import PressReview from '../PressReview/PressReview';
import styles from './Reviews.module.css';

export type Rating = {
  type?: string;
  value: number;
};

export type Review = {
  name?: string;
  review?: string;
  rating: Rating;
} & HashKey;

export type ReviewsProps = {
  reviews: Review[];
};

const cx = classNames.bind(styles);

/**
 * Reviews
 *
 * renders a list of reviews
 * @param reviews list of reviews to render
 */
function Reviews({ reviews }: ReviewsProps): JSX.Element | null {
  if (!reviews.length) {
    return null;
  }

  return (
    <ul className={cx('Reviews')}>
      {reviews.map(({ name, rating, hash }) => (
        <li className={cx('Reviews__item')} key={hash}>
          {name && <span className={cx('Reviews__name')}>{name}</span>}
          <div
            className={cx('Reviews__rating', {
              'Reviews__rating--telerama': rating.type === 'telerama',
            })}
          >
            <PressReview type={rating.type} rating={rating.value} />
          </div>
        </li>
      ))}
    </ul>
  );
}

export default Reviews;
