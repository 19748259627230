import classnames from 'classnames';
import type { JSX } from 'react';
import { useMemo } from 'react';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import styles from './SafetyCodeInputFieldTV.css';

const SAFETY_CODE_INPUT_FIELD_TV = 'safety_code_input_field_tv';

export interface ISafetyCodeInputFieldProps {
  filledInputs: number;
  inputLength: number;
  error?: string;
}

function SafetyCodeInputFieldTV({
  filledInputs,
  inputLength,
  error,
}: ISafetyCodeInputFieldProps): JSX.Element {
  const numberOfInputs = useMemo(
    () => [...Array(inputLength).keys()],
    [inputLength]
  );

  const inputs = useMemo(
    () =>
      numberOfInputs.map((el, index) => {
        return (
          <div
            data-testid={SAFETY_CODE_INPUT_FIELD_TV}
            className={classnames(styles.SafetyCodeInputFieldTV__inputItem, {
              [styles.SafetyCodeInputFieldTV__inputItemNext!]:
                index === filledInputs,
            })}
            key={`${SAFETY_CODE_INPUT_FIELD_TV}_${el}`}
          >
            {index < filledInputs && (
              <div
                data-testid={`${SAFETY_CODE_INPUT_FIELD_TV}_${index}_checked`}
                className={styles.SafetyCodeInputFieldTV__inputItemChecked}
              ></div>
            )}
          </div>
        );
      }),
    [filledInputs, numberOfInputs]
  );

  return (
    <div className={styles.SafetyCodeInputFieldTV}>
      <div className={styles.SafetyCodeInputFieldTV__inputContainer}>
        {inputs}
      </div>
      {!!error && <ErrorMessage isInputError>{error}</ErrorMessage>}
    </div>
  );
}

export default SafetyCodeInputFieldTV;
