import { getDefaultSeasonIndex } from '@canalplus/mycanal-sharedcomponent';
import type { QueryHandlerResult } from '../../../../../helpers/hooks/useQueryTemplate/types';
import { universalService } from '../../../../../services/UniversalService';
import { FetchRequestTypes } from '../../../../../services/types';
import type { FetchTemplateParameters } from '../../../../../templates/types';
import type { IMoreInfosState } from './types';

type GetFormattedMoreInfosParameters = Pick<
  FetchTemplateParameters,
  'URLPage' | 'hodorSdkConfig' | 'onClickParameters'
>;

const getFormattedMoreInfos = ({
  URLPage,
  hodorSdkConfig,
  onClickParameters,
}: GetFormattedMoreInfosParameters) =>
  universalService<QueryHandlerResult & IMoreInfosState>({
    url: URLPage,
    fetchDetails: {
      hodorSdkConfig,
      onClickParameters,
      template: FetchRequestTypes.MoreInfos,
    },
  });

type GetMoreInfosTemplateDataParameters = FetchTemplateParameters & {
  path: string;
};

export async function fetchMoreInfos({
  queryClient,
  URLPage,
  path,
  hodorSdkConfig,
  onClickParameters,
}: GetMoreInfosTemplateDataParameters): Promise<void> {
  const data = await queryClient.fetchQuery({
    queryKey: [URLPage], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      getFormattedMoreInfos({ URLPage, hodorSdkConfig, onClickParameters }),
  });

  const seasonsSelector = data?.selector || [];

  const { activeSeasonIndex } = getDefaultSeasonIndex(seasonsSelector, path);
  const activeSeason = seasonsSelector[activeSeasonIndex];

  if (!activeSeason?.onClick?.URLPage) {
    return;
  }
  const seasonURL = activeSeason?.onClick?.URLPage;

  await queryClient.prefetchQuery({
    queryKey: [seasonURL], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      getFormattedMoreInfos({ URLPage: seasonURL, hodorSdkConfig }),
  });
}
