import { DIMENSIONS } from '@canalplus/mycanal-commons';
import { ImageType } from '@canalplus/mycanal-sharedcomponent';
import type { JSX } from 'react';
import { useInvariantSelector } from '../../../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../../../helpers/hooks/useIsTvDevice';
import {
  applicationResizeSelector,
  imageLoadingSelector,
} from '../../../../../store/slices/application-selectors';

export type URLLogoProps = {
  URLLogoChannel?: string;
  URLLogoBrand?: string;
};

/**
 * URLLogo Component
 *
 * @param URLLogoChannel                 Logo for Channel
 * @param URLLogoBrand                   Logo for Brand
 */
function URLLogo({ URLLogoChannel, URLLogoBrand }: URLLogoProps): JSX.Element {
  const isTvDevice = useIsTvDevice();
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const imageLoading = useInvariantSelector(imageLoadingSelector);

  return (
    <>
      {URLLogoChannel && (
        <ImageType
          URLImage={{ default: URLLogoChannel }}
          dimensions={DIMENSIONS.LOGO_V5}
          isTvDevice={isTvDevice}
          resizeMode={resizeMode}
          loading={imageLoading}
        />
      )}
      {URLLogoBrand && (
        <ImageType
          URLImage={{ default: URLLogoBrand }}
          dimensions={DIMENSIONS.LOGO_V5}
          isTvDevice={isTvDevice}
          resizeMode={resizeMode}
          loading={imageLoading}
        />
      )}
    </>
  );
}

export default URLLogo;
