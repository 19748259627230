import { DisplayMode } from '@canalplus/mycanal-commons';
import type { IExternalServices } from '@canalplus/mycanal-sdk';
import { ModalTypes } from '../constants/modalTypes';
import Logger from '../helpers/logger/logger-helper';
import { launchExternalService } from '../store/reducers/actions/ExternalServices-actions';
import { getFeatureExternalServicesSelector } from '../store/slices/application-selectors';
import { openModal } from '../store/slices/modal';
import { setDisplayMode } from '../store/slices/ui';
import { setExternalServicesInfo } from '../store/slices/user';
import { externalServicesSelector } from '../store/slices/user-selectors';
import type { IState } from '../store/types/State-type';
import { fetchInit } from './Hodor/Init/fetchInit';
import { getHodorSdkConfig } from './Hodor/getSdkConfig';

/**
 * Helper that checks if the external service exists and is activated among all the external services
 * @param externalServices  External services object
 * @param tvPackID          TVPackID of the current offer
 * @returns True if the external service is activated, false if not, undefined if the external service is not found
 */
export const isExternalServiceActivated = (
  externalServices?: IExternalServices[],
  tvPackID?: string
): boolean | undefined => {
  return externalServices?.find(
    (externalService) => externalService.tvPackID === tvPackID
  )?.activationStatus;
};

/**
 * Fetches the external services from Hodor and dispatches the result
 * @returns External services object from Hodor's response
 */
export const fetchExternalServices =
  () =>
  async (
    dispatch: Redux.Dispatch,
    getState: () => IState
  ): Promise<IExternalServices[]> => {
    try {
      const state = getState();
      const config = getHodorSdkConfig({ dispatch, state });

      const init = await fetchInit({
        config,
        state,
      });

      const externalServices = init.userDetails.externalServices || [];
      dispatch(setExternalServicesInfo(externalServices));

      return externalServices;
    } catch (error) {
      Logger.error(
        `ExternalService::fetchExternalServices() - Error occurs during initExternalServices ${error}`
      );
      return [];
    }
  };

/**
 * Handle streaming external service activation by checking if the external service is activated or not and
 * open the modal to activate it if not
 * @param URLMedias       URL of the current offer
 * @param channelName     Channel name of the current offer
 * @param tvPackID        TVPackID of the current offer
 * @param clickedElement  Element that was clicked to trigger the external service
 * @returns Void
 */
export const handleStreamingExternalService =
  (
    URLMedias: string,
    channelName?: string,
    tvPackID?: string,
    clickedElement?: HTMLElement | null | undefined
  ) =>
  async (dispatch: Redux.Dispatch, getState: () => IState): Promise<void> => {
    try {
      const state = getState();
      const featExternalServices = getFeatureExternalServicesSelector(state);

      if (!featExternalServices || !tvPackID) {
        return;
      }

      // Verify if the external service is activated by checking the externalServices in store
      const externalServices = externalServicesSelector(state);
      if (isExternalServiceActivated(externalServices, tvPackID)) {
        // External service is activated, handle content consumption on external platform
        dispatch(launchExternalService(URLMedias));
        return;
      }

      // External service is not activated yet or out-dated store, re-fetch the external services from Hodor
      const fetchedExternalServices = await dispatch(fetchExternalServices());

      // External service is activated or not in hodor's externalServices list, handle content consumption on external platform
      if (
        [true, undefined].includes(
          isExternalServiceActivated(fetchedExternalServices, tvPackID)
        )
      ) {
        // External service has been activated since last authentication, handle content consumption on external platform
        dispatch(launchExternalService(URLMedias));
        return;
      }

      // External service in question still not activated, open its activation modal
      dispatch(setDisplayMode(DisplayMode.MODAL));
      dispatch(
        openModal({
          modalType: ModalTypes.EXTERNAL_SERVICE_STREAM_MODAL,
          modalProps: { channelName, tvPackID },
          clickedElement,
        })
      );
    } catch (error) {
      Logger.error(
        `ExternalService::handleExternalService() - Error occurs during fetchAndcheckActivationStatus ${error}`
      );
    }
  };
