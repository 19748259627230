import { useSelector } from 'react-redux';
import { ThemeColor } from '../../constants/themeColor';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import { offerLocationSelector } from '../../store/slices/application-selectors';
import { pageURLSelector } from '../../store/slices/page-selectors';
import { findTrackingSelector } from '../../store/slices/tracking-selectors';
import {
  abTestingPopulationSelector,
  isKidsProfileSelector,
  segTypeSelector,
} from '../../store/slices/user-selectors';
import { sendTrackingOnCTAEvent } from './tracking-helper';

export const useSendTrackingOnCTAEvent = (): ((type: string) => void) => {
  const abTestingPopulation = useSelector(abTestingPopulationSelector);
  const isKids = useSelector(isKidsProfileSelector);
  const isTvDevice = useIsTvDevice();
  const offerLocation = useInvariantSelector(offerLocationSelector);
  const pageURL = useSelector(pageURLSelector);
  const segType = useSelector(segTypeSelector);
  const tracking = useSelector(findTrackingSelector);

  return (type: string) =>
    sendTrackingOnCTAEvent({
      abTestingPopulation,
      isKids,
      offerLocation,
      pageURL,
      segType,
      themeColor: ThemeColor.Dark,
      tracking,
      type,
      version: $_BUILD_APP_VERSION,
      isTvDevice,
    });
};
