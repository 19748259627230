import { isClientSide } from '../window/isClientSide';

/**
 * Safely remove a class from an element
 *
 * @param {HTMLElement} elem
 */
const safeRemove =
  (elem: HTMLElement) =>
  (style: string): void => {
    if (!isClientSide()) {
      return;
    }

    if (elem.classList.contains(style)) {
      elem.classList.remove(style);
    }
  };

/**
 * removeImmersiveNoScroll
 *
 * removes noscroll css class from immersive
 * @returns {function}
 */
export const removeImmersiveNoScroll = (prefix?: string): void => {
  const immersiveEl = document.getElementById('immersive');
  if (immersiveEl) {
    safeRemove(immersiveEl)([prefix, 'noscroll'].filter(Boolean).join('-'));
  }
};
