import type { ApiV2DetailTab } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { useRef } from 'react';
import Tab from '../Tab/Tab';
import styles from './TabsList.css';

export type TabsListProps = {
  displayNameActivated?: string;
  switchTab: (tab: ApiV2DetailTab, index: number) => void;
  tabs: ApiV2DetailTab[];
};

function TabsList({
  displayNameActivated,
  switchTab,
  tabs,
}: TabsListProps): JSX.Element {
  const tabsListRef = useRef<HTMLUListElement>(null);

  return (
    <ul
      className={classNames(styles.tabsList, 'tabsListFocus')}
      ref={tabsListRef}
      role="tablist"
    >
      {tabs.map((tab, index) => (
        <Tab
          key={tab.displayName}
          index={index}
          displayName={tab.displayName}
          active={tab.displayName === displayNameActivated}
          onClick={() => switchTab(tab, index)}
          path={tab.path}
          tabsListRef={tabsListRef}
        />
      ))}
    </ul>
  );
}

export default TabsList;
