import { useEffect, useState } from 'react';
import { ADDED_TO_PLAYLIST } from '../../../../../../../constants/playlist';
import { useAppDispatch } from '../../../../../../../helpers/hooks/useAppDispatch';
import { useSendTrackingOnCTAEvent } from '../../../../../../../helpers/tracking/useSendTrackingOnCTAEvent';
import I18n from '../../../../../../../lang';
import { userListService } from '../../../../../../../services/PlaylistService';
import { setPersoUpdated } from '../../../../../../../store/slices/immersive';
import { useNextURLActionLayout } from '../../../../../../../templates/DetailV5/data/store/hooks';

/**
 * This hooks adds the content to the playlist then returns, if the action was done, true else isInPlaylist.
 * The add action is only tried if nextURLActionLayout (perso data) is set in Detail provider.
 * Moreover it dispatchs to show Snack (implemented in PlaylistButton).
 * @param {boolean}       [autoAddToPlaylist]    if the add to playlist should be done without user's action
 * @param {string}        [contentID]            the targeted contentID
 * @param {boolean}       [isDisabled]           if the action is disabled
 * @param {boolean}       [isInPlaylist]         isInPlaylist before any action
 * @param {boolean}       [isTvDevice]           if we are on a TV device
 * @param {void}          [onDisplayTooltip]     action to be executed on display tooltip
 * @returns {boolean} the new isInPlaylist status
 */
export function useAutoAddToPlaylist({
  autoAddToPlaylist,
  contentID,
  isDisabled,
  isInPlaylist,
  isTvDevice,
  onDisplayTooltip,
}: {
  autoAddToPlaylist: boolean;
  contentID: string;
  isDisabled: boolean;
  isInPlaylist: boolean;
  isTvDevice: boolean;
  onDisplayTooltip: ({ content }: { content: string }) => void;
}): boolean {
  const [isAutoAdded, setIsAutoAdded] = useState(false);

  // nextURLActionLayout is not undefined if the API call to get perso data was done
  const nextURLActionLayout = useNextURLActionLayout();

  const sendTrackingOnCTAEvent = useSendTrackingOnCTAEvent();
  const dispatch = useAppDispatch();

  const { t } = I18n.useTranslation();

  useEffect(() => {
    if (
      !autoAddToPlaylist ||
      isDisabled ||
      isTvDevice ||
      !nextURLActionLayout
    ) {
      return;
    }

    if (isInPlaylist) {
      return onDisplayTooltip({ content: t('PlaylistButton.alreadyAdded') });
    }

    if (!isAutoAdded) {
      try {
        dispatch(userListService('add', [contentID], 'playlist'));
        dispatch(setPersoUpdated(true));

        sendTrackingOnCTAEvent(ADDED_TO_PLAYLIST);
        onDisplayTooltip({ content: t('PlaylistButton.added') });
        setIsAutoAdded(true);
      } catch (error) {
        onDisplayTooltip({ content: t('PlaylistButton.error') });
      }
    }
  }, [
    autoAddToPlaylist,
    contentID,
    dispatch,
    isAutoAdded,
    isDisabled,
    isInPlaylist,
    isTvDevice,
    nextURLActionLayout,
    onDisplayTooltip,
    sendTrackingOnCTAEvent,
    t,
  ]);

  return isAutoAdded || isInPlaylist;
}
