import { Template } from '@canalplus/sdk-hodor';
import type { ApiV2DetailTab } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';

/**
 * Helper function that retrieves default tab index from the tabs provided by Hodor, but if not exists, -1 is returned
 * @param tabs list of detailV5 tabs
 * @returns a tab index from the list of tabs or -1
 */
const getHodorTabIndex = (tabs: ApiV2DetailTab[]): number =>
  tabs.findIndex((tab) => tab.default === true);

/**
 * Helper function that retrieves the tab index from the list of tabs based on the one specified in the path, but if none specified, -1 is returned
 * @param tabs list of detailV5 tabs
 * @param pathname path of the detail page
 * @returns a tab index from the list of tabs or -1
 */
const getPathnameTabIndex = (
  tabs: ApiV2DetailTab[],
  pathname: string
): number =>
  tabs.findIndex((tab) => {
    const lastUrlSegment = pathname?.split('/').filter(Boolean).pop() || '';
    // The root url of a detailPage always ends with a id param like this: XXXXX_XXXXX
    // In this case we only need to check the strict equality between pathname and tab.path
    const isDetailPageRootUrl = /^\d*_\d*$/.test(lastUrlSegment);
    // In the case of a url that targets a tab, it is important to compare only the last segment of the url because Hodor allows this type of url:
    // /series/django/h/20869234_50001/saison-1/resume-casting/ where "/season-1/" is a dynamic parameter which will never be present in the path of the tabs.
    return isDetailPageRootUrl
      ? tab.path === pathname
      : tab.path?.includes(lastUrlSegment);
  });

/**
 * Helper function that retrieves from a list of tabs and pathname,
 * the tab index that corresponds to the one specified in the path, but if none specified,
 * it returns the tab index that corresponds to the one marked as default by Hodor, if not exists, it returns 0 as ultimate fallback
 * @param tabs list of detailV5 tabs
 * @param path path of the detail page
 * @returns a tab index from the list of tabs and isTabTargetedByUrl which indicates if the url directly targets one of the tabs
 */
export const getDefaultTabIndex = (
  tabs: ApiV2DetailTab[],
  path: string
): { defaultTabIndex: number; isTabTargetedByUrl: boolean } => {
  const pathnameIndex = getPathnameTabIndex(tabs, path);

  if (pathnameIndex !== -1) {
    return { defaultTabIndex: pathnameIndex, isTabTargetedByUrl: true };
  }

  const hodorIndex = getHodorTabIndex(tabs);
  if (hodorIndex !== -1) {
    return { defaultTabIndex: hodorIndex, isTabTargetedByUrl: false };
  }

  return { defaultTabIndex: 0, isTabTargetedByUrl: false };
};

/**
 * Helper function that removes `moreInfos` tab from given `tabs`.
 * Useful for TV devices where MORE INFOS should not be displayed as a TabPanel, but as a Modal instead.
 * @param tabs DetailV5 tabs
 * @returns DetailV5 tabs for TV
 */
export const getTvTabs = (tabs: ApiV2DetailTab[]): ApiV2DetailTab[] =>
  tabs.filter((tab) => tab.displayTemplate !== Template.MoreInfos);
