import type { JSX } from 'react';
import Unicode from '../../../../../constants/unicode';
import styles from './Summary.css';

export type SummaryProps = {
  text?: string;
  prefix?: string;
};

function Summary({ text, prefix }: SummaryProps): JSX.Element | null {
  return text ? (
    <p className={styles.summary}>
      {prefix && (
        <span>
          {prefix}
          {Unicode.Space}
        </span>
      )}
      {text}
    </p>
  ) : null;
}

export default Summary;
