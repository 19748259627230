import type { JSX } from 'react';
import { useActionCreators } from '../../helpers/hooks/useActionCreators';
import { userListService } from '../../services/PlaylistService';
import { setPersoUpdated } from '../../store/slices/immersive';
import type { MoodProps } from './Mood';
import Mood from './Mood';

export function MoodConnected({
  contentId,
  userRating,
  ...restProps
}: Omit<
  MoodProps,
  | 'contentIDs'
  | 'setPersoUpdated'
  | 'updateMoodDisplay'
  | 'userListService'
  | 'userRating'
> &
  Partial<Pick<MoodProps, 'userRating'>> & {
    contentId?: string;
  }): JSX.Element {
  const contentIDs = [contentId || ''];

  const actions = useActionCreators({
    setPersoUpdated,
    userListService,
  });

  return (
    <Mood
      {...restProps}
      {...actions}
      contentIDs={contentIDs}
      userRating={userRating}
    />
  );
}
