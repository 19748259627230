import { Button } from '@canalplus/dive';
import type { ApiV2ContextualStrateOffer } from '@dce-front/hodor-types/api/v2/contextual_offer_page/definitions';
import classNames from 'classnames';
import type { JSX } from 'react';
import { OfferPrice } from '@canalplus/mycanal-sharedcomponent';
import styles from './MajorOffer.css';
import { TypePrice } from '../../../../helpers/const';

export type MajorOfferProps = ApiV2ContextualStrateOffer & {
  /** custom className */
  className?: string;

  /** if the component has click on all card */
  hasClickOnCard?: boolean;

  /** function triggered when user clicking on the component */
  onClick?: () => void;

  /** aria label for prices on button */
  ariaLabelPrices?: string;
};

/** This component is used to display MajorOffer  */
export function MajorOffer({
  className,
  hasClickOnCard,
  prices,
  onClick,
  shortTitle,
  subtitle,
  title,
  ariaLabelPrices,
}: MajorOfferProps): JSX.Element {
  return (
    <button
      type="button"
      className={classNames(styles.majorOffer, className, {
        'globalMajorOffer': hasClickOnCard,
      })}
      {...(hasClickOnCard && { onClick })}
    >
      <div className={styles.majorOffer__textWrapper}>
        <div className={styles.majorOffer__info}>
          <div className={styles.majorOffer__info__title}>{title}</div>
          <div className={styles.majorOffer__info__subtitle}>{subtitle}</div>
        </div>
        {prices && (
          <OfferPrice
            className={styles.majorOffer__offerPrice}
            typePrice={TypePrice.Major}
            prices={prices}
            ariaLabel={ariaLabelPrices}
          />
        )}
      </div>
      {!hasClickOnCard && (
        <Button
          className={styles.majorOffer__button}
          onClick={onClick}
          width="fixed"
        >
          {shortTitle}
        </Button>
      )}
    </button>
  );
}
