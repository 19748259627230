/**
 * Allows to extract number from an input id
 * of this format: input-0
 *
 * @param inputId
 * @returns The number extracted
 */
export const extractNumberFromInputId = (inputId: string): number => {
  return parseInt(inputId.split('-')[1] || '', 10);
};

/** Pattern to check if the value is only a number */
export const ONE_NUMBER_PATTERN = '[0-9]{1}';
