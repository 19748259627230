import { HorizontalList } from '@canalplus/mycanal-sharedcomponent';
import { Binder, memory, spatial } from '@canalplus/one-navigation';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames';
import type { JSX } from 'react';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { MetaTagsTemplate } from '../../../../../../components/MetaTags/MetaTagsTemplate';
import { useIsFrom } from '../../../../../../components/Page/useIsFrom';
import Spinner from '../../../../../../components/Spinner/Spinner';
import { QueryKeys } from '../../../../../../constants/queryKeys';
import useInfiniteQueryTemplate from '../../../../../../helpers/hooks/useInfiniteQueryTemplate/useInfiniteQueryTemplate';
import { useIsTvDevice } from '../../../../../../helpers/hooks/useIsTvDevice';
import {
  scroll,
  scrollFocusedIntoHorizontalList,
} from '../../../../../../helpers/oneNavigation/scroll';
import type { FetchDetails } from '../../../../../../services/types';
import { FetchRequestTypes } from '../../../../../../services/types';
import { profileIdSelector } from '../../../../../../store/slices/user-selectors';
import { usePrimaryActionContentId } from '../../../../data/store/hooks';
import {
  getEnabledSecondaryActions,
  getPrimaryActionTypes,
} from '../../../ActionLayout/helpers';
import type {
  FormattedEpisodes,
  InitialDataPaginate,
} from '../../EpisodesList.types';
import { getEpisodesNextPageUrl } from '../../data/getEpisodesNextPageUrl';
import Episode from '../Episode/Episode';
import EpisodeEditorial from '../EpisodeEditorial/EpisodeEditorial';
import EpisodeIoSentinel from '../EpisodeIoSentinel/EpisodeIoSentinel';
import EpisodeMedia from '../EpisodeMedia/EpisodeMedia';
import styles from './EpisodesContainer.css';
import { useForceFocusOnRefetch } from './hooks/useForceFocusOnRefetch';

export type EpisodesListProps = {
  URLPage: string;
  disableMetaUpdate?: boolean;
  initialData?: InitialDataPaginate;
  onClickParameters?: FetchDetails['onClickParameters'];
  seasonUrl?: string;
};

/**
 * Displays spinner during episode data fetching
 */
function EpisodesFetchingSpinner() {
  return (
    <div className={styles.spinner__content}>
      <Spinner size={5} />
    </div>
  );
}
/**
 * `EpisodesContainer` queries Episodes data used to render individual Episode components.
 *
 * When on a TV device, the Episode components are rendered wrapped inside a Binder with HorizontalList.
 */
function EpisodesContainer({
  disableMetaUpdate = false,
  initialData,
  onClickParameters,
  seasonUrl,
  URLPage,
}: EpisodesListProps): JSX.Element | null {
  const profileId: number | undefined = useSelector(profileIdSelector);
  const isTvDevice = useIsTvDevice();
  const isFromDetail = useIsFrom(Template.DetailPage);
  const primaryActionContentId = usePrimaryActionContentId();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const handleOnScroll = useCallback(() => setActiveIndex(-1), []);

  const episodeListUrl = seasonUrl || URLPage;

  const [{ isLoading, fetchNextPage, hasNextPage, data, isFetchingNextPage }] =
    useInfiniteQueryTemplate<FormattedEpisodes>(
      episodeListUrl || undefined,
      {
        options: {
          profileId,
          queryKeyPrefix: QueryKeys.DetailEpisodes,
        },
        onClickParameters,
        template: FetchRequestTypes.EpisodeContainer,
      },
      {
        initialData,
        getNextPageParam: (lastPageData) =>
          getEpisodesNextPageUrl(lastPageData),
      }
    );

  useEffect(() => {
    setActiveIndex(0);
  }, [seasonUrl]);

  useForceFocusOnRefetch({ seasonUrl, primaryActionContentId, isTvDevice });

  const middleware = useMemo(() => {
    return [scroll([scrollFocusedIntoHorizontalList()]), memory(), spatial()];
  }, []);

  if (isLoading) {
    return (
      <div className={styles.episodesContainer}>
        <Spinner />
      </div>
    );
  }

  if (!data) {
    return null;
  }

  const episodes = data.pages.map(({ episodes: { contents } }) =>
    contents?.map(
      ({
        contentID,
        actionLayout: {
          primaryActions: [
            {
              type,
              subtype,
              onClick,
              userProgress,
              startTime,
              endTime,
              description,
            } = {},
          ],
          secondaryActions,
        },
        altImage,
        URLImage,
        isCompleted,
        title,
        editorialTitle,
        summary,
        stickers,
        durationLabel,
        availabilityEndDateLabel,
        seasonNumber,
        episodeNumber,
      }) => {
        const primaryActionTypes = getPrimaryActionTypes({ type, subtype });
        const { d2g, pvr } = getEnabledSecondaryActions(secondaryActions);
        return (
          <Episode
            key={contentID}
            id={`${seasonUrl ? 'episodesList-season' : 'episodesList'}-item-${contentID}`}
            pvr={pvr}
            contentID={contentID}
            seasonNumber={seasonNumber}
            episodeNumber={episodeNumber}
            onClick={onClick}
            primaryActionTypes={primaryActionTypes}
            media={
              <EpisodeMedia
                URLImage={URLImage}
                altImage={altImage}
                isCompleted={isCompleted}
                userProgress={userProgress}
                startTime={startTime}
                endTime={endTime}
                primaryActionTypes={primaryActionTypes}
                stickers={stickers}
                pvr={pvr}
              />
            }
            editorial={
              <EpisodeEditorial
                title={title}
                editorialTitle={editorialTitle}
                description={description}
                summary={summary}
                contentID={contentID}
                subtype={subtype}
                durationLabel={durationLabel}
                availabilityEndDateLabel={availabilityEndDateLabel}
                hasD2G={!!d2g}
              />
            }
          />
        );
      }
    )
  );

  if (isTvDevice) {
    return (
      <div className={styles.episodesContainer}>
        <Binder middleware={middleware}>
          <HorizontalList
            activeIndex={activeIndex}
            showControls={false}
            onScroll={handleOnScroll}
          >
            {episodes}
            <li className={styles['episodesContainer__sentinel-wrapper-tv']}>
              <EpisodeIoSentinel
                isEnabled={hasNextPage}
                onIntersect={fetchNextPage}
                className={styles['episodesContainer__sentinel-tv']}
              />
              {isFetchingNextPage && <EpisodesFetchingSpinner />}
            </li>
          </HorizontalList>
        </Binder>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.episodesContainer, {
        [styles['episodesContainer--detailV5']!]: isFromDetail,
      })}
    >
      <MetaTagsTemplate data={data} disableMetaUpdate={disableMetaUpdate} />
      <ul>{episodes}</ul>
      <EpisodeIoSentinel
        isEnabled={hasNextPage}
        onIntersect={fetchNextPage}
        className={styles['episodesContainer__sentinel-web']}
      />
      {isFetchingNextPage && <EpisodesFetchingSpinner />}
    </div>
  );
}

export default memo(EpisodesContainer);
