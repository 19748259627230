import { Button } from '@canalplus/dive';
import { ModalV2, ModalV2Size } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import type { JSX } from 'react';
import { useIsTvDevice } from '../../../../../helpers/hooks/useIsTvDevice';
import { useOnFocusable } from '../../../../../helpers/hooks/useOnFocusable';
import { FocusManager } from '../../../../../helpers/oneNavigation/FocusManager';
import { MIDDLEWARE_FUNNEL_VOD } from '../../../../../helpers/oneNavigation/middleware';
import I18n from '../../../../../lang';
import type { PaymentMean } from '../../../../../templates/FunnelTvod/stores/types';
import { useDeleteCreditCard } from '../CrmInformation/hooks/useDeleteCreditCard';
import styles from './DeleteCBModal.css';

type DeleteCBModalProps = {
  /** Call when the modal is requested to close */
  onClose: () => void;
  /** focus manager to let know the parent we're ready to focus */
  focusManager?: FocusManager;
  /** The payment mean information related to a credit card */
  paymentMeanCBR: PaymentMean;
};

/**
 * Modal component designed to handle the deletion of a credit card.
 * It presents the user with information about the credit card being deleted and confirms the deletion action.
 * size "full-screen" on TV and "small" on desktop.
 */
export function DeleteCBModal({
  onClose,
  paymentMeanCBR,
  focusManager,
}: DeleteCBModalProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const isTvDevice = useIsTvDevice();
  const handleDeleteCreditCard = useDeleteCreditCard();

  useOnFocusable(focusManager);

  return (
    <ModalV2
      isTvDevice={isTvDevice}
      onBack={isTvDevice ? onClose : undefined}
      className={styles.DeleteCBModal}
      onClose={onClose}
      size={isTvDevice ? ModalV2Size.FullScreen : ModalV2Size.Small}
    >
      <Binder
        className={styles.DeleteCBModal__content}
        middleware={MIDDLEWARE_FUNNEL_VOD}
      >
        <div className={styles.DeleteCBModal__title}>
          {t('FunnelTvod.deleteCBRegistered')}
        </div>
        <div className={styles.DeleteCBModal__label}>
          {t('FunnelTvod.deleteCBModal.label', {
            cardNumber: paymentMeanCBR?.contractPaymentMeanInfo || '',
          })}
        </div>
        <div className={styles.DeleteCBModal__subLabel}>
          {t('FunnelTvod.deleteCBModal.subLabel')}
        </div>
        <Button
          onClick={async () => {
            await handleDeleteCreditCard({
              contractId: String(paymentMeanCBR?.contractId),
            });
            onClose();
          }}
          width={isTvDevice ? 'fixed' : 'full'}
        >
          {t('FunnelTvod.deleteCBModal.confirmBtn')}
        </Button>
        {isTvDevice && (
          <Button
            className={styles.DeleteCBModal__cancelBtn}
            onClick={onClose}
            width="fixed"
            variant="secondary"
            font="hind"
          >
            {t('FunnelTvod.deleteCBModal.cancelBtn')}
          </Button>
        )}
      </Binder>
    </ModalV2>
  );
}
