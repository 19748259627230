import type { ApiV2HapiGetMediaUrl } from '@dce-front/hodor-types';
import type { ApiV2CoverTrailer } from '@dce-front/hodor-types/api/v2/detail/spyro/definitions';
import {
  DEFAULT_CACHE_TIME,
  DEFAULT_STALE_TIME,
} from '../../../constants/reactQuery';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FetchRequestTypes } from '../../../services/types';
import type { VideoContent } from '../types';

const getVideoContent = (
  dataMedia: ApiV2HapiGetMediaUrl
): VideoContent | undefined => {
  const contentID = dataMedia.detail.informations.contentID;
  const encryption =
    dataMedia.detail.informations.playsets.available?.[0]?.encryption;

  const url =
    // @ts-expect-error: videoUrl is received but videoURL is not declared in type
    dataMedia.detail.informations.playsets.available?.[0]?.videoUrl ||
    dataMedia.detail.informations.playsets.available?.[0]?.videoURL;

  if (encryption === 'clear' && url) {
    return {
      encryption: 'clear',
      url,
    };
  }

  if ((encryption === 'encrypted' || !encryption) && contentID) {
    return {
      encryption: 'encrypted',
      contentID,
    };
  }

  return undefined;
};

type UseGetVideoContentFromUrlMediaOptions = {
  /**
   * Url to fetch the contentID for onePlayer
   */
  urlMedias: ApiV2CoverTrailer['URLMedias'];
  /**
   * Fetch request type for url medias request
   */
  template?: FetchRequestTypes;
};

export function useGetVideoContentFromUrlMedia({
  urlMedias,
  template = FetchRequestTypes.MediaUrl,
}: UseGetVideoContentFromUrlMediaOptions): VideoContent | undefined {
  // Fetch data for the video
  const [{ data: dataMedia }] = useQueryTemplate<ApiV2HapiGetMediaUrl>(
    urlMedias,
    {
      template,
    },
    {
      enabled: !!urlMedias,
      staleTime: DEFAULT_STALE_TIME,
      gcTime: DEFAULT_CACHE_TIME,
    }
  );

  if (!dataMedia) {
    return undefined;
  }

  return getVideoContent(dataMedia);
}
