import type { DiveProps } from '../../../types/Dive.types';
import type { RatingsVariant } from '../Ratings.types';
/**
 * The fill states for the stars in ratings.
 *
 * Used for the rating variants of "primary" and "secondary"
 *
 * Half fill is only for Primary variant.
 */
export enum RatingsStarFill {
  Full = 'full',
  Half = 'half',
  Empty = 'empty',
}

export type RatingsStarProps = {
  /** The variant of star, can be Primary or Secondary */
  variant: `${Exclude<RatingsVariant, RatingsVariant.Tertiary>}`;
  /** Fill state of the start, can be full, half or empty */
  fill: RatingsStarFill;
} & DiveProps;
