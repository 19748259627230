import type { ApiV2SportVitrinePage } from '@dce-front/hodor-types';
import { universalService } from '../../../services/UniversalService';
import { FetchRequestTypes } from '../../../services/types';
import type { FetchTemplateParameters } from '../../../templates/types';

type GetSportVitrineDataParameters = FetchTemplateParameters & {
  dispatch?: Redux.Dispatch;
};

export async function fetchSportVitrine({
  dispatch,
  hodorSdkConfig,
  onClickParameters,
  queryClient,
  URLPage,
}: GetSportVitrineDataParameters): Promise<void> {
  await queryClient.fetchQuery({
    queryKey: [URLPage], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      universalService<ApiV2SportVitrinePage>({
        url: URLPage,
        fetchDetails: {
          hodorSdkConfig,
          onClickParameters,
          template: FetchRequestTypes.SportVitrine,
        },
        formatterProps: { dispatch },
      }),
  });
}
