import type { JSX } from 'react';
import styles from './EditorialTitle.css';

export type EditorialTitleProps = {
  title: string;
};

/**
 * editorialTitle Component
 *
 * @param editorialTitle                 return information about content (type, hour...)
 */
function EditorialTitle({ title }: EditorialTitleProps): JSX.Element {
  return <span className={styles.editorialTitle_text}>{title}</span>;
}

export default EditorialTitle;
