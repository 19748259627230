import {
  FloatingArrow,
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset as offsetFloatingUI,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useTransitionStyles,
} from '@floating-ui/react';
import classNames from 'classnames';
import type { ReactElement } from 'react';
import React, { cloneElement, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDiveContext } from '../../context';
import type { TooltipProps } from './Tooltip.types';
import {
  TooltipPosition,
  TooltipTransition,
  TooltipViewWidth,
} from './Tooltip.types';
import { tooltipViewCVA } from './TooltipView.cva';
import {
  ARROW_HEIGHT,
  ARROW_OFFSET,
  ARROW_WIDTH,
  TRANSITIONS,
  TRANSITION_DURATIONS,
} from './constants';

/**
 * Maximum length of content of tooltip to determine if tooltip is single or multiple lines.
 * To style the link inline (if content is single line)
 * Or the link is separate line (if content is multiple lines)
 */
const TOOLTIP_CONTENT_LONG_LENGTH_THRESHOLD = 50;

/**
 * A Tooltip is a small text that appears when the user hovers over an element.
 * It is used to explain an interface element or feature to the user.
 *
 * @example
 * <Tooltip content="description" position='top'>
 *    <p>text</p>
 * </Tooltip>
 */
export const Tooltip = React.forwardRef<ReactElement, TooltipProps>(
  function Tooltip(
    {
      content,
      id,
      position = TooltipPosition.TopCenter,
      className,
      children,
      isVisible = false,
      showOnHover = true,
      width = TooltipViewWidth.Fluid,
      transition = TooltipTransition.MoveFade,
      link,
      'data-testid': dataTestId = 'dive-tooltip',
    }: TooltipProps,
    propRef
  ): JSX.Element {
    const { isTv } = useDiveContext();
    const idGenerated = useMemo(() => id || uuidv4(), [id]);
    const [isOpen, setIsOpen] = useState(false);
    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
      open: isOpen || isVisible,
      onOpenChange: setIsOpen,
      placement: position,
      // Make sure the tooltip stays on the screen
      whileElementsMounted: autoUpdate,
      middleware: [
        offsetFloatingUI({
          mainAxis: ARROW_OFFSET.px + ARROW_HEIGHT.px,
          alignmentAxis: -ARROW_HEIGHT.px,
        }),
        flip(),
        // eslint-disable-next-line react-compiler/react-compiler
        arrow({ element: arrowRef }),
      ],
    });
    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

    const { isMounted, styles: stylesTransition } = useTransitionStyles(
      context,
      {
        duration: TRANSITION_DURATIONS,
        initial: ({ side }) => {
          return TRANSITIONS[transition][side];
        },
      }
    );

    // Event listeners to change the open state
    const hover = useHover(context, { enabled: showOnHover });
    const focus = useFocus(context, { enabled: showOnHover });
    const dismiss = useDismiss(context, { enabled: showOnHover });
    const intractions = [focus, hover, dismiss];

    // Merge all the interactions into prop getters
    const { getReferenceProps, getFloatingProps } =
      useInteractions(intractions);
    const isLongContent =
      typeof content === 'string' &&
      content.length >= TOOLTIP_CONTENT_LONG_LENGTH_THRESHOLD;

    if (isTv || !content) {
      return children;
    }

    const visibleTooltip = (isOpen || isVisible) && isMounted;

    return (
      <>
        {React.cloneElement(
          children,
          getReferenceProps({
            ref,
            ...children.props,
            'aria-describedby': idGenerated,
          })
        )}

        <FloatingPortal>
          {visibleTooltip && (
            <div
              id={idGenerated}
              role="tooltip"
              data-testid={dataTestId}
              // by default use a z-index 2000 (arbitrary value) to be sure the tooltip is over all the elements
              className={classNames('z-[2000]', className)}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              <div
                style={stylesTransition}
                className={tooltipViewCVA({
                  width,
                  isLongContent,
                })}
              >
                <span>{content}</span>
                {link &&
                  cloneElement(link, {
                    className: classNames({
                      'mt-dt-spacing-100': isLongContent,
                      'ms-dt-spacing-100': !isLongContent,
                    }),
                    hasIcon: isLongContent,
                  })}
                <FloatingArrow
                  className="fill-dt-theme-surface-tooltip-tooltip"
                  width={ARROW_WIDTH.px}
                  height={ARROW_HEIGHT.px}
                  ref={arrowRef}
                  context={context}
                />
              </div>
            </div>
          )}
        </FloatingPortal>
      </>
    );
  }
);
