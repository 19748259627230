import { fetch } from '@canalplus/mycanal-fetch';
import type { LogApiMetadata } from '@canalplus/mycanal-logger';
import { Service } from '@canalplus/mycanal-logger';
import type { CheckSafetyCodeResponse } from '@canalplus/types-acm';
import { AcmErrorCode } from '@canalplus/types-acm';
import type { AcmSdkConfig } from '../config/types';
import type { AcmApi } from '../constants/paths';
import { AcmError } from '../error/AcmError';
import { AcmLockedSafetyCodeError } from '../error/AcmLockedSafetyCodeError';
import { getFilledApiUrl } from '../helpers/getFilledApiUrl';
import { isAcmApiError } from '../helpers/isAcmApiError';
import { logError } from '../helpers/logError';

/** @category Function parameters */
export type CheckParentalCodeParameters = AcmSdkConfig & {
  passToken: string;
  parentalCode: string;
  contextId?: string;
};

/**
 * Fetch **checkParentalCode** ACM Api
 *
 * - `contextId` is optional and will be used to generate the `validationToken` if provided
 * - `validationToken` from the response is only defined if the `parentalCode` is checked
 */
export async function checkParentalCode({
  appKeyLog,
  contextId,
  endpoint,
  fetchOptions,
  logger,
  logMetadata,
  offerLocation,
  offerZone,
  passToken,
  parentalCode,
  requestId,
}: CheckParentalCodeParameters): Promise<CheckSafetyCodeResponse> {
  const startAt = Date.now();
  const api: AcmApi = 'checkParentalCode';

  const url = getFilledApiUrl({
    api,
    endpoint,
    offerLocation,
    offerZone,
  });

  const baseErrorLogs = {
    ...logMetadata,
    endpoint: url,
    offerLocation,
    offerZone,
    route: api,
    startAt,
    req: { appData: { appKey: appKeyLog }, ...(requestId && { requestId }) },
  } satisfies LogApiMetadata;

  try {
    const checkParentalCodeResponse = await fetch(url, {
      ...fetchOptions,
      headers: {
        ...fetchOptions?.headers,
        passToken,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ parentalCode, ...(contextId && { contextId }) }),
    });

    logger.info(
      `ACM ${api} ${checkParentalCodeResponse.status}`,
      logger.generateApiMetadata(Service.Acm, api, {
        ...baseErrorLogs,
        statusCode: checkParentalCodeResponse.status,
      })
    );

    const parsedCheckParentalCode = await checkParentalCodeResponse.json();

    if (checkParentalCodeResponse.ok) {
      if (checkParentalCodeResponse.status === 204) {
        throw new Error(
          `ACM ${api} ${checkParentalCodeResponse.status}, code does not exist`
        );
      }

      return parsedCheckParentalCode as CheckSafetyCodeResponse;
    }

    if (isAcmApiError(parsedCheckParentalCode)) {
      const { errorCode, errorDescription, statusCode } =
        parsedCheckParentalCode;

      if (errorCode === AcmErrorCode['Code_401.2']) {
        throw new AcmLockedSafetyCodeError();
      }

      throw new AcmError(errorCode, errorDescription, statusCode);
    }

    throw new Error(`ACM ${api} ${checkParentalCodeResponse.status}`);
  } catch (error) {
    logError({ error, logger, baseErrorLogs, api });

    throw error;
  }
}
