import type { IContent } from '@canalplus/mycanal-sdk';
import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import type { RefObject, SyntheticEvent } from 'react';
import type { D2GModalProps } from '../typings/downloadToGo';

export enum ContentType {
  Season = 'season',
  Movie = 'movie',
}

export interface Id2gHandlerProps {
  openOfflinePlayer: (id: string) => void;
  informationButtonHandler: (
    event: SyntheticEvent,
    ref: RefObject<HTMLElement>,
    props: D2GModalProps
  ) => void;
  openEpisodeListModal: (content: IContentD2G) => void;
}

export interface IContentD2G extends IContent {
  content?: IContentD2G[];
  contentType?: ContentType;
  seasonInfo?: ISeasonInfo;
  idItem?: string;
  stickers?: ApiV2SpyroStrateContentSticker[];
}

export interface ISeasonInfo {
  URLImage?: string;
  label?: string;
  number?: number;
  seasonId?: string;
  seasonSelected?: number;
  seasonTitle?: string;
  showId?: string;
}

export interface IContentDownloaded {
  duration?: number;
  id?: string;
  isFinished?: boolean;
  metadata: IContentD2G;
  progress?: number;
  size?: number;
  transport?: string;
  url?: string;
}

export enum DownloadStateId {
  NotDownloaded = 'notDownloaded',
  DownloadInProgress = 'downloadInProgress',
  DownloadPaused = 'downloadPaused',
  Downloaded = 'downloaded',
  LicenseExpired = 'licenseExpired',
}
