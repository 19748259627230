import customProtocolCheckLib from 'custom-protocol-check';
import { UAParser } from 'ua-parser-js';

let DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT: number;

const { browser } = UAParser();

const isSafariIOS = () => browser.name === 'Mobile Safari';

const registerEvent = (
  target: Window,
  eventType: string,
  cb: EventListenerOrEventListenerObject
) => {
  if (target.addEventListener) {
    target.addEventListener(eventType, cb);
    return {
      remove() {
        target.removeEventListener(eventType, cb);
      },
    };
  } else {
    (<any>target).attachEvent(eventType, cb);
    return {
      remove() {
        (<any>target).detachEvent(eventType, cb);
      },
    };
  }
};

const createHiddenIframe = (target: HTMLElement, uri: string) => {
  // eslint-disable-next-line react/iframe-missing-sandbox
  const iframe: HTMLIFrameElement = document.createElement('iframe');

  iframe.src = uri;
  iframe.id = 'hiddenIframe';
  iframe.style.display = 'none';

  target.appendChild(iframe);

  return iframe;
};

const openUriWithHiddenFrame = (
  uri: string,
  failCb: () => void,
  successCb: () => void
) => {
  const timeout = setTimeout(() => {
    failCb();
    handler.remove();
  }, DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT);

  let iframe = <HTMLIFrameElement>document.querySelector('#hiddenIframe');

  if (!iframe) {
    iframe = createHiddenIframe(document.body, 'about:blank');
  }

  const onBlur = () => {
    clearTimeout(timeout);
    handler.remove();
    successCb();
  };
  const handler = registerEvent(window, 'blur', onBlur);

  if (iframe.contentWindow) {
    iframe.contentWindow.location.href = uri;
  }
};

const customProtocolCheckSafariIOS = (
  uri: string,
  failCb: (() => void) | undefined,
  successCb: (() => void) | undefined,
  timeout = 2000
) => {
  const failCallback = () => {
    return failCb && failCb();
  };

  const successCallback = () => {
    return successCb && successCb();
  };

  const openUri = () => {
    openUriWithHiddenFrame(uri, failCallback, successCallback);
  };

  if (timeout) {
    DEFAULT_CUSTOM_PROTOCOL_FAIL_CALLBACK_TIMEOUT = timeout;
  }

  if (document.hasFocus()) {
    openUri();
  } else {
    const focusHandler = registerEvent(window, 'focus', () => {
      focusHandler.remove();
      openUri();
    });
  }
};

export const customProtocolCheck = (
  uri: string,
  failCb?: () => void,
  successCb?: () => void,
  timeout?: number,
  unsupportedCb?: () => void
): void =>
  isSafariIOS()
    ? customProtocolCheckSafariIOS(uri, failCb, successCb, timeout)
    : customProtocolCheckLib(uri, failCb, successCb, timeout, unsupportedCb);
