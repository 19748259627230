import { Ratings, RatingsVariant } from '@canalplus/dive';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { ReviewType } from '../../../../../constants/reviews';
import styles from './PressReview.module.css';

export type PressReviewProps = {
  /** type of pressRating */
  type?: string;
  /** rating number */
  rating: number;
};

const TYPE_TO_VARIANT = {
  [ReviewType.Telerama]: RatingsVariant.Tertiary,
  [ReviewType.Telecable]: RatingsVariant.Secondary,
} satisfies Partial<Record<`${ReviewType}`, `${RatingsVariant}`>>;

const cx = classNames.bind(styles);

/**
 * PressReview Component
 *
 * This component is in charge of displaying
 * reviews notation depending on the type of press.
 * The component sizing depends on the height of its wrapper.
 *
 * @param type type of pressRating
 * @param rating rating number
 */
function PressReview({ type, rating }: PressReviewProps): JSX.Element | null {
  if (type === ReviewType.SensCritique) {
    return <span className={cx('PressReview__score')}>{rating}</span>;
  }

  return (
    <Ratings
      rating={rating}
      variant={
        TYPE_TO_VARIANT[type as keyof typeof TYPE_TO_VARIANT] ||
        RatingsVariant.Primary
      }
    />
  );
}

export default PressReview;
