import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { userChangeSettings } from '../../../store/slices/user';
import {
  areAnimationsMutedSelector,
  areAnimationsTemporarilyUnmutedSelector,
  isTrailerMutedSelector,
  settingsSelector,
} from '../../../store/slices/user-selectors';

const getHasUserBeenActive = () => {
  try {
    return navigator.userActivation?.hasBeenActive;
  } catch (error) {
    return false;
  }
};

/**
 * Helper to retrieve mute value by priority (temporarilyValue > preferredUnmute > settings value)
 */
const getIsMuted = ({
  preferredUnmuted,
  settingsValue,
  temporarilyUnmuted,
}: {
  /**
   * Should prefer sound for the current feature if possible
   * (e.g: Short Video List, whenever we enter, it should have sound if possible)
   */
  preferredUnmuted: boolean;
  /**
   * The value of sound in settings, saved in local storage of the device
   */
  settingsValue: boolean;
  /**
   * The value of sound if the user changed it during his session, saved in redux
   */
  temporarilyUnmuted?: boolean;
}): boolean => {
  // if the user unmuted an embedded video
  if (temporarilyUnmuted) {
    return false;
  }
  // if the feature favored sound on and the user did not mute an embedded video
  if (
    preferredUnmuted &&
    getHasUserBeenActive() &&
    temporarilyUnmuted !== false
  ) {
    return false;
  }
  // return the value from settings
  return settingsValue;
};

/**
 * Users can mute or unmute the animations
 * This value is stored in local storage and redux store
 */
export const useAreAnimationsMuted = (
  preferredUnmuted = false
): {
  /**
   * Are all video animations (excluded the trailer) currently muted
   * by priority (temporarilyValue > preferredUnmute > settings value)
   */
  areAnimationsMuted: boolean;
  /**
   * Are all video animations (excluded the trailer) muted in the user settings saved in local storage
   */
  areAnimationsMutedInSettings: boolean;
  /**
   * Is Trailer feature currently muted
   * by priority (temporarilyValue > preferredUnmute > settings value)
   */
  isTrailerMuted: boolean;
  /**
   * Is Trailer feature muted in the user settings saved in local storage
   */
  isTrailerMutedInSettings: boolean;
  /**
   * Toggle the muted value of all video animations (excluded the trailer)
   * in the user settings saved in local storage
   */
  toggleAreAnimationsMutedInSettings: () => void;
  /**
   * Toggle the muted value of Trailer feature in the user settings saved in local storage
   */
  toggleIsTrailerMutedInSettings: () => void;
} => {
  const dispatch = useAppDispatch();
  const settings = useSelector(settingsSelector);

  const areAnimationsTemporarilyUnmuted = useSelector(
    areAnimationsTemporarilyUnmutedSelector
  );

  const areAnimationsMutedInSettings = useSelector(areAnimationsMutedSelector);
  const toggleAreAnimationsMutedInSettings = useCallback(() => {
    dispatch(
      userChangeSettings({
        ...settings,
        areAnimationsMuted: !areAnimationsMutedInSettings,
      })
    );
  }, [areAnimationsMutedInSettings, dispatch, settings]);

  const isTrailerMutedInSettings = useSelector(isTrailerMutedSelector);
  const toggleIsTrailerMutedInSettings = useCallback(() => {
    dispatch(
      userChangeSettings({
        ...settings,
        isTrailerMuted: !isTrailerMutedInSettings,
      })
    );
  }, [dispatch, isTrailerMutedInSettings, settings]);

  return {
    areAnimationsMuted: getIsMuted({
      preferredUnmuted,
      settingsValue: areAnimationsMutedInSettings,
      temporarilyUnmuted: areAnimationsTemporarilyUnmuted,
    }),
    areAnimationsMutedInSettings,
    isTrailerMuted: getIsMuted({
      preferredUnmuted,
      settingsValue: isTrailerMutedInSettings,
      temporarilyUnmuted: areAnimationsTemporarilyUnmuted,
    }),
    isTrailerMutedInSettings,
    toggleAreAnimationsMutedInSettings,
    toggleIsTrailerMutedInSettings,
  };
};
