import type { JSX } from 'react';
import { useGetTermsOfSaleUrl } from '../../../../../helpers/hooks/useGetTermsOfSaleUrl';
import { FocusManager } from '../../../../../helpers/oneNavigation/FocusManager';
import LoadableTextBrut from '../../../../../templates/TextBrut';

type TermsOfSaleProps = {
  focusManager?: FocusManager;
};

export function TermsOfSale({ focusManager }: TermsOfSaleProps): JSX.Element {
  const termsOfSaleUrl = useGetTermsOfSaleUrl();

  return <LoadableTextBrut url={termsOfSaleUrl} focusManager={focusManager} />;
}
