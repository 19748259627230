import { Button, ButtonIcon, WebMenuAccountSvg } from '@canalplus/dive';

type ConnectButtonProps = {
  label?: string;
  isTvDevice: boolean;
};

export const ConnectButton = ({ label, isTvDevice }: ConnectButtonProps) => {
  return isTvDevice ? (
    <Button
      data-testid="connect-button-tv"
      icon={<WebMenuAccountSvg />}
      iconPosition="left"
      id="default"
      variant="primary"
      width="full"
      font="hind"
    >
      {label}
    </Button>
  ) : (
    <ButtonIcon
      data-testid="connect-button"
      aria-label={label}
      icon={<WebMenuAccountSvg />}
      label={label}
    />
  );
};
