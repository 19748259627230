import { SafetyCodeContext } from '@canalplus/types-acm';
import type { JSX } from 'react';
import { cloneElement, memo } from 'react';
import QrCodeMyCanal from '../../../../assets/svg/qrCodeMycanal.svg';
import QrCodeTIM from '../../../../assets/svg/qrCodeTIM.svg';
import { isTim } from '../../../../helpers/application/application-helper';
import I18n from '../../../../lang';
import styles from './SafetyCodeInputQrCodeTV.css';
import { SafetyCodeAccountUrl } from './types';

export interface ICodeInputQrCodeProps {
  safetyCodeContext: SafetyCodeContext;
}

/**
 * Component that displays the QR Code for safety code
 *
 * @param safetyCodeContext  ACM Context parentalCode | purchaseCode
 */
function CodeInputQrCode({
  safetyCodeContext,
}: ICodeInputQrCodeProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const QrCodeIcon = isTim() ? <QrCodeTIM /> : <QrCodeMyCanal />;
  const safetyForgottenUrl = isTim()
    ? SafetyCodeAccountUrl.Tim
    : SafetyCodeAccountUrl.MyCanal;
  const isTimPurchase =
    isTim() && safetyCodeContext === SafetyCodeContext.Purchase;
  const isParentalContext = safetyCodeContext === SafetyCodeContext.Parental;

  return (
    <div className={styles.CodeInputQrCodeTV}>
      <div className={styles.CodeInputQrCodeTV__titleContainer}>
        <h2 className={styles.CodeInputQrCodeTV__title}>
          {t(`SafetyDisclaimer.${safetyCodeContext}.createTitle`)}
        </h2>
        <p className={styles.CodeInputQrCodeTV__text}>
          {t(`SafetyDisclaimer.${safetyCodeContext}.createText`)}
          <span className={styles.CodeInputQrCodeTV__link}>
            {' '}
            {safetyForgottenUrl}{' '}
          </span>
          {(isTimPurchase || isParentalContext) && (
            <span className={styles.CodeInputQrCodeTV__text}>
              {t(`SafetyDisclaimer.${safetyCodeContext}.subCreateText`)}
            </span>
          )}
        </p>
      </div>
      <div className={styles.CodeInputQrCodeTV__icon}>
        {cloneElement(QrCodeIcon, {
          title: safetyForgottenUrl,
          className: styles.CodeInputQrCodeTV__icon,
        })}
      </div>
    </div>
  );
}

export default memo(CodeInputQrCode);
