import { isClientSide } from '../window/isClientSide';

/**
 * Safely add a class to an element
 *
 * @param {HTMLElement} elem
 */
const safeAdd =
  (elem: HTMLElement) =>
  (style: string): void => {
    if (!isClientSide()) {
      return;
    }

    if (!elem.classList.contains(style)) {
      elem.classList.add(style);
    }
  };

/**
 * addImmersiveNoScroll
 *
 * adds noscroll css class to immersive element, or {prefix}-noscroll- if prefix is provided
 * @returns {function}
 */
export const addImmersiveNoScroll = (prefix?: string): void => {
  const immersiveEl = document.getElementById('immersive');
  if (immersiveEl) {
    safeAdd(immersiveEl)([prefix, 'noscroll'].filter(Boolean).join('-'));
  }
};
